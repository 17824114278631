.modal-footer {
    padding: $modal-inner-padding * .5;
}

.modal {
  .modal-header {
    .close {
      background: transparent;
      border: none;
      font-size: 1.5em;
      padding: 0;
    }
  }
 .card {
  .select2-container {
    display: block;
  }
 }
}
